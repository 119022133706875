<template>
	<Popup
		:visible.sync="localVisible"
		@closePopup="$emit('closeForm')"
	>
		<CreateBasketForm
			@closeForm="$emit('closeForm')"
			@openInvitePopup="$emit('openInvitePopup')"
		/>
	</Popup>
</template>
<script>
export default {
	name: "CreateBasketFormPopup",
	components: {
		CreateBasketForm: () => import("@/components/form/CreateBasketForm.vue")
	},
	props: {
		visible: {
			type: Boolean,
			default: false
		}
	},
	data()
	{
		return {
			localVisible: false,
		};
	},
	watch: {
		visible()
		{
			this.localVisible = this.visible;
		}
	}
}
</script>